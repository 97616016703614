.footer {
 /*    padding: 20px; */
    background-color: #f1f1f1;
    text-align: center;
  }
  
  .mapContainer iframe {
    max-width: 100%;
    width: 90%;
    height: 400px;
    border: none;
  }
  
  .typingTitle2 {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    font-size: 30px; /* Tamaño de la fuente */
    color: #030303; /* Color de texto blanco */
    white-space: nowrap;
    overflow: hidden;
    margin-top: 0.5em;
    display: inline-block;
    margin-bottom: 1em;
   
  }

  .headerFooter {
   display: flex;
    height: 15vh;
    background-color: #172933;
    display: flex;
    align-items: center;
   flex-direction: row-reverse;
}
.text-copy{
  padding: 0.5em;
  margin: 0.5em;
  color: white;
  text-align: justify;
  font-size: 13px;
  width: 30vw;
  
}

/* Para el texto  */
@media (max-width: 1650px) {
  .text-copy {
    width: 35vw;
  }
}
@media (max-width: 1500px) {
  .text-copy {
    width: 35vw;
  }
}
@media (max-width: 1200px) {
  .text-copy {
      padding: 0;
      font-size: 11px;
      margin: 10px;
      line-height: 1.3;
      width: 35vw;

  }
}
@media (max-width: 1100px) {
  .text-copy {
    width: 35vw;
  }
}

@media (max-width: 1000px) {
  .text-copy {
      width: 40vw;
  }
}

@media (max-width: 800px) {
  .text-copy {
   width: 40vw;

}
}

@media (max-width: 750px) {
  .text-copy {
      width: 45vw;
  }
}
@media (max-width: 700px) {
  .text-copy {
      width: 45vw;
  }
}
@media (max-width: 550px) {
  .text-copy {
      width: 45vw;
      font-size: 10px;
      line-height: 1.4;
  }
  .typingTitle2{
    font-size: 25px;
  }
}
@media (max-width: 400px) {
  .text-copy {
      width: 45vw;
      font-size: 7px;
      line-height: 1.4;
  }
 
}